import type { PageProps } from 'gatsby';

import * as React from 'react';
import { rem } from 'polished';
import { styled } from '../gatsby-theme-stitches/stitches.config';
import IndexPage from './index';
import StoryPageTemplate from '../templates/story';

type NotFoundPageProps = PageProps<{
}>;

const Container = styled('div', {
  $contentWrapper: true,
  marginY: rem(200),
  fontSize: '3rem',
  fontWeight: 'bold',
});

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  return (
    <Container>Not found :(</Container>
  );
};

export default NotFoundPage;